:root {
  --bp-sm: 576px;
  --bp-md: 768px;
  --bp-lg: 992px;
  --bp-xl: 1200px;
  --bp-xxl: 1400px;
  --bp-sl: 1600px;
  --gut: 8px;
  --guty: 10px;
  --ln: 26.6px;
}

@media screen and (min-width: 768px) {
  :root {
    --gut: 26px;
    --guty: 20px;
  }
}

ul, li { margin: 0; padding: 0; list-style: none; }

body {
  margin: 1em;
  margin-top: 16px;
  color: #000;
  background: #fff;
  font-size: 19px;
}

/* page margin at bigger breaks */
@media screen and (min-width: 768px) {
  body {
    margin: 2em;
  }
}
@media screen and (min-width: 1400px) {
  body {
    margin-right: 4em;
  }
}

/* site headers */
h1,
h3 {
  color: #000;
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 19px; 
}
/* h1 {
  font-size: 19px; 
} */

/* project title */
h3 {
  /* font-size: 19px;  */
  font-family: monospace;
  letter-spacing: 0.03rem;
}

/* character measurements */
h1,
p {
  letter-spacing: 0.02rem;
}

body, h1, h3 {
  line-height: 1.4;
}

@media screen and (min-width: 768px) {
  body, h1, h3 {
    /* font-size: 16px; */
  }
}

p {
  margin: 0;
}

.frame {
  max-width: var(--bp-xxl);
  margin: 0 auto;
}

header {
  margin-bottom: 2em;
}

nav {
  position: fixed;
  margin: 1rem;
  margin-top: 4rem;
  top: 0;
  right: 0;
  /* outline: 1px red solid; */
}

nav > ul > li {
  margin: 0.5em 0;
}

nav button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  cursor: pointer;
}

/*
 * Projects
 */
.projects > li {
  margin-bottom: calc(var(--ln) * 4);
}

.projects .info {
}
.projects .info.w p {
  display: none;
}
.projects .info.w h3 {
  display: block;
}
.projects .info.n {
  margin-top: calc(var(--ln) * 1.4);
}
.projects .info.n h3 {
  display: none;
}

.projects .info > div {
  max-width: 30rem;
}

.projects .info h3 {
  margin-bottom: var(--ln);
}

.projects .info p + p {
  margin-top: var(--ln);
}

.projects .info .tech {
  font-family: monospace;
  font-size: 13px;
}

.projects .info .tech span {
  display: inline-block;
  margin-right: 1.25rem;
  margin-bottom: 0.5rem;
}
.projects .info .tech span:last-child {
  margin-right: 0;
}

.projects .info .tech,
.projects .info > p:last-of-type {
}

.projects .info .text {
  /* max-width: 60em; */
  /* margin-top: 0.5em; */
  /* outline: 1px blue solid; */
}

.projects .info .role {
}

/*
 * Preview (assets)
 */


.preview {
  position: relative;
  /* margin-left: calc(var(--gut) * -.75); */
  /* margin-right: calc(var(--gut) * -.75); */
}

/* @media screen and (min-width: 768px) { */
/*   .preview { */
/*     margin-left: 0; */
/*     margin-right: 0; */
/*   } */
/* } */

/* asset list item wrapper */
.preview > li {
  position: relative;
  margin-top: calc(var(--guty) * 1.5);
}

/* make sure children don't add height */
.preview > li div {
  line-height: 0;
}

/* all asset media elements fill their container */
.preview img,
.preview video {
  width: 100%;
  /* max-width: 100%; */
}

/* aspect ratio context container */
.preview .aspect {
  height: 0;
  overflow: hidden;
}

/*
 * Asset columns
 */
/* 
  Tablet 2 columns 
  By default 2 column assets begin with tablet width
*/
@media screen and (min-width: 768px) {
  .preview {
    margin-top: 0;
    display: flex;
    flex: 6;
    flex-wrap: wrap;
    margin-top: calc(var(--guty) * -1);
    margin-left: calc(var(--gut) * -.5);
    margin-right: calc(var(--gut) * -.5);
  }
  .preview > li {
    box-sizing: border-box;
    width: 50%;
    margin-top: calc(var(--guty));
    padding: 0 calc(var(--gut) * .5);
  }
  /* .preview > li:nth-child(even) {
  } */
}
/*
  Repeated for projects that always default to columns
*/
.project-mini .preview,
.project-sawaya .preview,
.project-c1 .preview,
.project-harley .preview.n,
.project-sirius .preview {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--guty) * -1);
  margin-left: calc(var(--gut) * -.5);
  margin-right: calc(var(--gut) * -.5);
}
.project-mini .preview > li,
.project-sawaya .preview > li,
.project-c1 .preview > li,
.project-harley .preview.n > li,
.project-sirius .preview > li {
  box-sizing: border-box;
  padding: 0 calc(var(--gut) * .5);
}

@media screen and (max-width: 767px) {
  .preview {
    margin-left: -8px;
    margin-right: -8px;
  }
  .project-mini .preview,
  .project-sawaya .preview,
  .project-c1 .preview,
  .project-harley .preview.n,
  .project-sirius .preview {
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(var(--gut) * -.5 - 8px);
    margin-right: calc(var(--gut) * -.5 - 8px);
  }
}

/* 
  Project level asset columns
*/
/* Mini & Sawaya 2 columns always */
.project-mini .preview > li,
.project-sawaya .preview > li {
  width: 50%;
}

/* C1 3|1 always */
.project-c1 .preview > li:nth-child(1) {
  width: 75%;
}
.project-c1 .preview > li:nth-child(2) {
  width: 25%;
}

/* AIC 1 item 100% */
.project-aic .preview > li:nth-child(1) {
  width: 100%;
}

/*
  GE 
  1st item 100%
  2nd cross align center
*/
.project-ge .preview > li:nth-child(1) {
  width: 100%;
}
.project-ge .preview > li:nth-child(2) {
  align-self: center;
}

/*
  Harley
  Extra nested lists for column arrangement
  Mobile layout 2 col on long scroll images
*/
@media screen and (max-width: 767px) {
  .project-harley .preview.w {
    display: none;
  }
  .project-harley .preview.n > li {
    width: 50%;
  }
  .project-harley .preview.n > li:nth-child(1),
  .project-harley .preview.n > li:nth-child(4) {
    width: 100%;
  }
  .project-harley .preview.n > li:nth-child(1) { order: 1; }
  .project-harley .preview.n > li:nth-child(2) { order: 3; }
  .project-harley .preview.n > li:nth-child(3) { order: 2; }
  .project-harley .preview.n > li:nth-child(4) { order: 4; }
}
@media screen and (min-width: 768px) {
  .project-harley .preview.n {
    display: none;
  }
  .project-harley .preview.w > li {
    margin-top: 0;
  }
  .project-harley .preview.w > li li {
    margin-top: calc(var(--guty));
  }
}

/* Sirius masonry */
.project-sirius .preview > li:nth-child(1) { width: 63%; }
.project-sirius .preview > li:nth-child(2) { width: 37%; }
.project-sirius .preview > li:nth-child(3) { width: 25%; }
.project-sirius .preview > li:nth-child(4) { width: 25%; }
.project-sirius .preview > li:nth-child(5) { width: 50%; }

/*
 * Sidebar project info
 */
@media screen and (min-width: 992px) {
  .projects > li {
    display: flex;
  }
  .projects .info.n {
    display: none;
  }
  .projects .info.w p {
    display: block;
  }
  .projects .info {
    flex: 2;
    padding-right: var(--gut);
  }
}

@media screen and (min-width: 1200px) {
  .projects > li {
    display: flex;
  }
  /* .projects > li > div,
  .projects > li > ul {
  } */

  .projects > li > div:first-child {
    flex-shrink: 0;
    width: 12rem;
    /* margin-right: 2rem; */
  }

  .projects .info > div {
    margin-right: var(--gut);
  }

  .preview {
    /* flex: 8; */
  }
}


/*
 * Preview Chrome
 */
.preview .chrome {
  /* filter: drop-shadow(-2px 3px 3px #888); */
  box-shadow: -2px 3px 5px #999;
  /* -moz-box-shadow: -2px 3px 3px 1px #888; */
  /* box-shadow: -2px 3px 3px 1px #888; */
  overflow: hidden;
}

.preview .chrome.desktop {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding-top: 12px;
  background: #eee;

}
.preview .chrome.desktop > div {
  border-top: 1px solid #ccc;
}

.preview .chrome.tablet {
  border-radius: 3px;
  padding: 12px;
  background: #eee;
}
/* .preview .chrome.tablet > div {
  border: 1px solid #fff;
} */

.preview .chrome.phone {
  border-radius: 3px;
  padding-top: 16px;
  padding-bottom: 16px;
  background: #eee;
}
.preview .chrome.phone > div {
  margin-left: 2.5px;
  margin-right: 2.5px;
}

.preview .chrome.applet {
  border-radius: 3px;
  background: #eee;
}

/* * {
  outline: 1px solid rgba(0, 0, 0, 0.1);
} */
